import React, { useEffect, useState } from 'react';
import { Box, Heading, Flex, Text, Button } from '@chakra-ui/react';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { configFile } from '@/config';

import GradientButton from '@/components/GradientButton';
import { handleGTMClick } from '@/helpers/handleGTMClick';

interface Props {
  title: string;
  description?: string;
}

export default function BannerMainPage({ title, description }: Props) {
  const [isMobile, setIsMobile] = useState<Boolean>();

  const { t } = useTranslation('common');
  const router = useRouter();

  useEffect(() => {
    setIsMobile(window.innerWidth <= 830);

    const bannerCard = document.querySelectorAll('.onBanner');

    bannerCard[0]?.nextElementSibling?.classList.add('show');

    bannerCard.forEach((item) => {
      item.addEventListener('mouseenter', () => {
        document.querySelector('.show')!.classList.remove('show');
        item.nextElementSibling!.classList.add('show');
      });
    });
  }, [title]);

  return (
    <Box
      as="section"
      width="100%"
      bgColor={configFile.primaryAccentColor}
      height={{ base: '993px', md: '713px' }}
      paddingBottom={{ base: '56px', lg: '80px' }}
      paddingTop={{ base: '56px', lg: '80px' }}
      position="relative"
    >
      <Image
        fill
        style={{ objectFit: 'cover' }}
        src="/images/default/background.svg"
        sizes="(max-width: 768px) 75vw, 768px"
        alt={title}
      />
      <Box
        maxWidth="1216px"
        margin="0 auto"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        columnGap="32px"
        width="90%"
      >
        <Flex
          width="100%"
          height="100%"
          direction={{ base: 'column', lg: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          position="relative"
        >
          <Flex gap="16px" direction="column" maxW={{ base: '100%', lg: '535px' }} justifyContent="center" mt="40px">
            <Flex
              alignItems={{ base: 'flex-start', lg: 'center' }}
              gap="24px"
              direction={{ base: 'column', lg: 'row' }}
            >
              <Heading
                color={configFile.tertiaryAccentColor}
                fontSize={{ base: '36px', lg: '48px' }}
                lineHeight={{ base: '40px', lg: '60px' }}
                as="h1"
              >
                {title}
              </Heading>
            </Flex>
            {description && (
              <Text fontSize="18px" lineHeight="28px" color={configFile.tertiaryAccentColor}>
                {description}
              </Text>
            )}
            <Button
              id="overview_contact"
              aria-label="contact page"
              size="md"
              h="48px"
              maxW={{ base: '100%', lg: '289px' }}
              color={configFile.tertiaryAccentColor}
              bgColor={configFile.fourthAccentColor}
              _hover={{ bgColor: configFile.fiveAccentColor }}
              mt="48px"
              onClick={(e) => handleGTMClick(e, () => router.push('/contato'))}
            >
              {t('homepage.banner.button')}
            </Button>
            {!isMobile && (
              <Flex w="100%" alignItems="center" justifyContent="flex-start" mt="104px">
                <Box>
                  <GradientButton bottom />
                </Box>
                <Text w="100%" color={configFile.tertiaryAccentColor}>
                  {t('homepage.banner.footer')}
                </Text>
              </Flex>
            )}
          </Flex>
          {!isMobile && (
            <Box
              w="112px"
              h="112px"
              position="absolute"
              top="50%"
              right="40%"
              transform={{ base: 'translateY(0)', lg: 'translateY(-50%)' }}
            >
              <GradientButton search big />
            </Box>
          )}
          {isMobile && (
            <Box mt="32px">
              <GradientButton search big />
            </Box>
          )}
          <Box justifySelf="flex-end">
            <Image
              priority
              src="/images/homepage/illustration1.svg"
              alt="Ilustração de páginas de internet com o dizer 'www.SEUSITE.com.br'"
              width={506}
              height={531}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
